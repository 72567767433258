import moment from 'moment'
import { STATUS_TYPES } from 'constants/qualityControl'
import translatedCellRenderer from 'tableCells/TranslatedCell'
import {
  PacketAssigneeNameCell,
  PacketLanguageCell,
  PacketLocationStateCell,
  PacketDaysLeftCell,
  PacketTurfNameCell,
  PacketDateUploadedCell,
  PacketDateCollectedCell,
  PacketQcOfficeCell,
  PacketQcStatusCell,
} from './SortablePacketCells'
import {
  PacketAssigneeNameCsvCell,
  PacketDateCollectedCsvCell,
  PacketDateUploadedCsvCell,
  PacketDaysLeftCsvCell,
  PacketLanguageCsvCell,
  PacketLocationStateCsvCell,
  PacketTurfNameCsvCell,
} from './SortablePacketCsvCells'

export default {
  defaultSortColumn: 'qc_deadline',
  defaultSortOrder: 'asc',
  label: 'packets',
  columns: [
    {
      dataKey: 'name',
      title: 'Packet',
      type: 'string',
      autoResize: true,
      resizable: true,
      headerCell: translatedCellRenderer,
    },
    {
      dataKey: 'forms_count',
      title: 'Scan count',
      type: 'number',
      autoResize: true,
      resizable: true,
      headerCell: translatedCellRenderer,
    },
    {
      dataKey: 'assignee_full_name',
      title: 'Assigned to',
      type: 'string',
      autoResize: true,
      resizable: true,
      headerCell: translatedCellRenderer,
      bodyCell: PacketAssigneeNameCell,
      csvCell: PacketAssigneeNameCsvCell,
    },
    {
      dataKey: 'status',
      title: 'QC Status',
      type: 'enum',
      options: STATUS_TYPES,
      autoResize: true,
      resizable: true,
      headerCell: translatedCellRenderer,
      bodyCell: PacketQcStatusCell,
    },
    {
      dataKey: 'turf_phone_verification_language',
      title: 'Language',
      type: 'enum',
      options: [
        { label: 'en', value: 'en' },
        { label: 'es', value: 'es' },
      ],
      autoResize: true,
      resizable: true,
      filterable: true,
      bodyCell: PacketLanguageCell,
      csvCell: PacketLanguageCsvCell,
    },
    {
      dataKey: 'location_state',
      title: 'State',
      type: 'string',
      autoResize: true,
      resizable: true,
      bodyCell: PacketLocationStateCell,
      csvCell: PacketLocationStateCsvCell,
    },
    {
      dataKey: 'qc_deadline',
      title: 'Days left',
      type: 'number',
      autoResize: true,
      resizable: true,
      bodyCell: PacketDaysLeftCell,
      csvCell: PacketDaysLeftCsvCell,
      headerCell: translatedCellRenderer,
      filterConstructor: rule => ({
        ...rule,
        column: 'days_left',
      }),
    },
    {
      dataKey: 'created_at',
      title: 'Date uploaded',
      type: 'date',
      autoResize: true,
      resizable: true,
      filterable: false,
      sortable: true,
      bodyCell: PacketDateUploadedCell,
      csvCell: PacketDateUploadedCsvCell,
      headerCell: translatedCellRenderer,
    },
    {
      dataKey: 'shift_start',
      title: 'Date collected',
      type: 'date',
      autoResize: true,
      resizable: true,
      bodyCell: PacketDateCollectedCell,
      csvCell: PacketDateCollectedCsvCell,
      headerCell: translatedCellRenderer,
      filterConstructor: rule => {
        if (rule.operator !== 'is') {
          return {
            ...rule,
            param: moment(rule.param).toISOString(),
          }
        }
        return {
          id: rule.id,
          rules: [
            {
              column: rule.column,
              operator: 'before',
              param: moment(rule.param).add(1, 'day').toISOString(),
            },
            {
              column: rule.column,
              operator: 'after',
              param: moment(rule.param).toISOString(),
            },
          ],
        }
      },
    },
    {
      dataKey: 'turf_name',
      title: 'Turf',
      type: 'string',
      autoResize: true,
      resizable: true,
      bodyCell: PacketTurfNameCell,
      csvCell: PacketTurfNameCsvCell,
      headerCell: translatedCellRenderer,
    },
    {
      key: 'qc_office',
      dataKey: 'qc_office',
      title: 'QC Office',
      type: 'string',
      autoResize: true,
      resizable: true,
      filterable: false,
      bodyCell: PacketQcOfficeCell,
      headerCell: translatedCellRenderer,
    },
  ],
}
