import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { List, ListItem, LoadBar, CardError } from 'components'
import Uppy from '@uppy/core'
import XHRUpload from '@uppy/xhr-upload'
import { DragDrop } from '@uppy/react'
import { withTranslation } from 'react-i18next'
import {
  Button,
  ButtonBlock,
  Icon,
  Font,
  Modal,
} from '@politechdev/blocks-design-system'
import classNames from 'classnames/bind'
import styles from './Uploader.module.scss'
import PDFSplitter from './PDFSplitter'
import CharacterValidator from './CharacterValidator'

class Uploader extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    endpoint: PropTypes.string.isRequired,
    fileTypes: PropTypes.array,
    dataValidator: PropTypes.func,
    fileValidator: PropTypes.func,
    onReset: PropTypes.func,
    onUpload: PropTypes.func,
    onComplete: PropTypes.func,
    public: PropTypes.bool,
    stringifyData: PropTypes.bool,
    splitPDFs: PropTypes.bool,
    validateUTF8: PropTypes.bool,
  }

  static defaultProps = {
    fileTypes: null,
    public: false,
    stringifyData: true,
    fileValidator: () => null,
  }

  constructor(props) {
    super(props)
    this.state = {
      dialogVisible: !!props.open,
      loadingCount: 0,
      fileData: this.props.currentFile || null,
      error: '',
    }

    const isV2Endpoint = props.endpoint.startsWith('/system')

    this.uploader = new Uppy({
      id: props.label,
      restrictions: {
        maxNumberOfFiles: props.splitPDFs ? null : 1,
        allowedFileTypes: props.fileTypes,
        maxTotalFileSize: 20_000_000,
      },
      autoProceed: true,
      onBeforeFileAdded: file => {
        const fileError = props.fileValidator(file)

        if (fileError) {
          this.setState({ error: fileError })
          return false
        }
      },
    })

    if (props.splitPDFs) this.uploader.use(PDFSplitter)

    if (props.validateUTF8) this.uploader.use(CharacterValidator)

    this.uploader.use(XHRUpload, {
      endpoint: `//${
        isV2Endpoint ? process.env.API_HOSTNAME : process.env.SYSTEM_HOSTNAME
      }${props.endpoint}`,
      fieldName: 'file',
      headers: {
        authorization: `Basic ${process.env.SYSTEM_AUTH_TOKEN}`,
      },
    })

    this.uploader.on('file-added', file => {
      if (file.source === 'pdfsplitter') {
        this.setState(current => ({
          loadingCount: current.loadingCount + 1,
        }))
      } else {
        this.setState(current => ({
          filePreview: file,
          loadingCount: current.loadingCount + 1,
        }))
      }

      isV2Endpoint &&
        this.uploader.setFileMeta(file.id, {
          public: this.props.public,
        })
    })

    this.uploader.on('upload-success', (_, { body: { data, url } }) => {
      const error = this.props.dataValidator
        ? this.props.dataValidator(data)
        : ''
      const formattedData = this.props.stringifyData
        ? JSON.stringify(data)
        : data

      this.setState(current => ({
        fileData: formattedData,
        loadingCount: current.loadingCount - 1,
        url,
        error,
      }))

      if (this.props.autoUpload) {
        this.handleUpload(formattedData, url)
      }
    })

    this.uploader.on('upload-error', (_, __, response) => {
      if (response?.status === 413) {
        return this.setState(current => ({
          error: this.props.t('The file uploaded was too large'),
          loadingCount: current.loadingCount - 1,
        }))
      }
      this.setState(current => ({
        error: this.props.t('There was an error uploading this file'),
        loadingCount: current.loadingCount - 1,
      }))
    })

    this.uploader.on('complete', result => {
      if (!result.failed.length && this.props.onComplete) {
        this.props.onComplete(result.successful)
      }
    })

    this.uploader.on('restriction-failed', (_, error) => {
      if (!this.state.error) {
        this.setState(current => ({
          error: this.props.t(error.message),
          loadingCount: current.loadingCount - 1,
        }))
      } else {
        this.setState(current => ({
          loadingCount: current.loadingCount - 1,
        }))
      }
    })
  }

  handleUpload = async (fileData, url) => {
    const { dialog } = this.props
    this.setState(current => ({ loadingCount: current.loadingCount + 1 }))

    if (this.props.onUpload) {
      await this.props.onUpload(
        fileData || this.state.fileData,
        url || this.state.url
      )
    }

    this.setState(current => ({ loadingCount: current.loadingCount - 1 }))
    if (dialog) this.hideDialog()
  }

  showDialog = () => {
    this.setState({ dialogVisible: true })
  }

  hideDialog = () => {
    this.setState({ dialogVisible: false })
    this.reset()
  }

  reset = () => {
    this.setState({
      filePreview: null,
      fileData: null,
      loadingCount: 0,
      error: '',
    })
    this.props.onReset?.()
    this.uploader.reset()
  }

  renderUploadArea() {
    const { filePreview, loadingCount, error } = this.state
    const { dialog, autoUpload, disabled, hideCancelButton } = this.props
    const cx = classNames.bind(styles)

    if (filePreview || error) {
      return (
        <div className={styles.uploader}>
          <div className={styles.uploader__list}>
            {filePreview && (
              <List>
                <ListItem
                  removable
                  onRemove={this.reset}
                  primaryText={filePreview.name}
                />
              </List>
            )}
            <LoadBar show={loadingCount} />
            <CardError message={error} hide={error.length === 0} />
            {dialog || (
              <ButtonBlock justify="right">
                {autoUpload || (
                  <Button.Accent
                    onClick={() => this.handleUpload()}
                    disabled={loadingCount}
                  >
                    Upload
                  </Button.Accent>
                )}
                {!hideCancelButton && (
                  <Button.Secondary onClick={this.reset}>
                    Cancel
                  </Button.Secondary>
                )}
              </ButtonBlock>
            )}
          </div>
        </div>
      )
    }

    return (
      <div className={cx('uploader', { 'uploader--disabled': disabled })}>
        <div className={styles['uploader__drag-area']}>
          {disabled && <div className={styles.scrim} />}
          <DragDrop uppy={this.uploader} disabled />
          <LoadBar show={loadingCount} />
        </div>
      </div>
    )
  }

  renderDialog() {
    const { label, hintText } = this.props
    const { dialogVisible, fileData, loadingCount } = this.state
    const uploadLabel = `Upload${loadingCount ? 'ing' : ''}`

    return (
      <div className="margin--top">
        <Button onClick={this.showDialog}>
          <Icon.Upload />
          <span>{label}</span>
        </Button>
        {hintText ? <Font.Copy variant="hint">{hintText}</Font.Copy> : null}
        <Modal
          id="file-upload-dialog"
          isOpen={dialogVisible}
          title={label.startsWith('Upload') ? label : `Upload ${label}`}
          onHide={this.hideDialog}
        >
          <Modal.Body>{this.renderUploadArea()}</Modal.Body>
          <Modal.Actions>
            <ButtonBlock justify="right">
              <Button.Secondary onClick={this.hideDialog}>
                Cancel
              </Button.Secondary>
              <Button
                onClick={() => this.handleUpload()}
                disabled={!fileData || loadingCount}
              >
                {uploadLabel}
              </Button>
            </ButtonBlock>
          </Modal.Actions>
        </Modal>
      </div>
    )
  }

  render() {
    const { dialog } = this.props

    if (dialog) return this.renderDialog()

    return this.renderUploadArea()
  }
}

export default withTranslation()(Uploader)
